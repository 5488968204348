import React, { useEffect } from 'react'
import Ruler from '../components/Ruler'
import { useAppSelector } from '../app/hooks';
import HeightRuler from '../components/HeightRuler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
export default function Height({ nextScreen }: { nextScreen: any }) {
  const path = useAppSelector((state) => state.config.path);

  return (
    <article id="sec-height" className={'sec-height panel full-screen'}> 
            <div className='left-holder'>
<button className='btn btn-prev' onClick={() => {nextScreen("#sec-weight"); }}>
                             <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        </div>  
    <h1 className='m-3'>Height</h1>
      {/* <Ruler /> */}
      <div className='mt-3'>
        <HeightRuler />
        {/* <div className='btn-holder'>
          <button className='btn btn-weight' onClick={() => {nextScreen("#sec-age")}}>
            Next
          </button>
        </div> */}
      </div>
      <div className='right-holder'>
<button className='btn btn-next' onClick={() => {nextScreen("#sec-age"); }}>
                             <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                        </div>
    </article>
  );
}
