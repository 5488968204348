import React, { useState } from 'react';
import WeightJson from '../assets/jsons/weight.json';
import Lottie from 'react-lottie-player';
import { useAppSelector } from '../app/hooks';
import weightImage from "../assets/images/Other images/Other images/vecteezy_fat-man-holding-a-measurement-tape-for-check-out-his-body_9352072.png";
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
export default function Weight({nextScreen}: {nextScreen: any}) {
    const [weight, setWeight] = useState<string>("0");
    const path = useAppSelector((state) => state.config.path);
    return (
        <article id="sec-weight" className={'sec-weight panel full-screen'}> 
        <div className='left-holder'>
<button className='btn btn-prev' onClick={() => {nextScreen("#sec-weight"); }}>
                             <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        </div>  
        <h1>Your Weight</h1>

            <Row>
                <Col xs={12} md={6}>
                    <div className='weight-machine'>
                        <div className='weight-input'>
                            <div className='holder'>
                                <input type='number' min={20} max={300} value={weight} onChange={(e) => {
                                    setWeight(e.target.value);
                                }} />
                                <span>KG</span>
                                <div className='updown' >
                                    <button className='btn btn-arrow' onClick={() => {
                                        if(Number.parseInt(weight) < 300){
                                        setWeight((Number.parseInt( weight) + 1).toString());
                                        }
                                    }}><FontAwesomeIcon icon={faChevronUp}/> </button>
                                    <button className='btn btn-arrow' onClick={() => {
                                        setWeight(((Number.parseInt( weight) - 1).toString()));
                                    }}><FontAwesomeIcon icon={faChevronDown} /> </button>
                                </div>
                            </div>
                        </div>
                        <Lottie className='weight-icon' animationData={WeightJson} play />
                    </div>
                </Col>

                <Col md={6} className='hide'>
                    <img className='img-fluid img-right' src={weightImage} />
                </Col>

            </Row>

            {/* <div className='btn-holder'>
                <button className='btn btn-weight' onClick={() => {nextScreen("#sec-height")}}>
                    Next
                </button>
            </div> */}
            <div className='right-holder'>
<button className='btn btn-next' onClick={() => {nextScreen("#sec-height"); }}>
                             <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                        </div>
        </article>
    )
}
