import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface CalendarProps {
  options?: string;
  nextScreen: any;
}

export default function Age({ options, nextScreen }: CalendarProps) {
  const today = new Date();
  const [year, setYear] = useState<number>(today.getFullYear());
  const [month, setMonth] = useState<number>(today.getMonth());
  const [day, setDay] = useState<number>(today.getDate());
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const monthTag = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Parse options if provided
  useEffect(() => {
    if (options) {
      const [optYear, optMonth, optDay] = options.split("-").map(Number);
      const setDate = new Date(optYear, optMonth - 1, optDay);
      setYear(setDate.getFullYear());
      setMonth(setDate.getMonth());
      setDay(setDate.getDate());
    }
  }, [options]);

  const daysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const startDayOfMonth = (month: number, year: number) => {
    return new Date(year, month, 1).getDay();
  };

  const drawDays = () => {
    const days = [];
    const startDay = startDayOfMonth(month, year);
    const nDays = daysInMonth(month, year);
    let n = startDay;

    for (let i = 0; i < 42; i++) {
      days.push({ day: "", isDisabled: true });
    }

    for (let i = 1; i <= nDays; i++) {
      days[n] = { day: i.toString(), isDisabled: false };
      n++;
    }

    return days;
  };

  const clickDay = (day: string) => {
    const newSelectedDay = new Date(year, month, parseInt(day));
    setSelectedDay(newSelectedDay);
  };

  const prevMonth = () => {
    if (month < 1) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const nextMonth = () => {
    if (month >= 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const prevYear = () => {
    setYear(year - 1);
  };

  const nextYear = () => {
    setYear(year + 1);
  };

  const reset = () => {
    setMonth(today.getMonth());
    setYear(today.getFullYear());
    setDay(today.getDate());
    setSelectedDay(null);
  };

  const drawHeader = () => {
    return (
      <div className="header">
        <div className="head-day">
          {selectedDay ? selectedDay.getDate() : day}
        </div>
        <div className="head-month">
          {monthTag[month]} - {year}
        </div>
      </div>
    );
  };

  return (
    <article id="sec-age" className={'sec-age panel full-screen'}> 
      <div className='left-holder'>
<button className='btn btn-prev' onClick={() => {nextScreen("#sec-height"); }}>
                             <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        </div>  
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              <h1 className="heading-section">Your Age</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="elegant-calencar d-md-flex">
                <div className="wrap-header d-flex align-items-center">
                  <p id="reset" onClick={reset}>
                    Reset
                  </p>
                  <div id="header" className="p-0">
                    {/* Year Navigation */}
                    <div
                      className="prev-year-button d-flex align-items-center justify-content-center"
                      style={{"marginBottom" : "50px"}}
                      onClick={prevYear}
                    >
                      <FontAwesomeIcon icon={faChevronUp} />
               
                    </div>

                    {/* Month Navigation */}
                    <div
                      className="pre-button d-flex align-items-center justify-content-center"
                      onClick={prevMonth}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                 
                    </div>

                    <div className="head-info">{drawHeader()}</div>

                    {/* Month Navigation */}
                    <div
                      className="next-button d-flex align-items-center justify-content-center"
                      onClick={nextMonth}
                    >
          
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>

                    {/* Year Navigation */}
                    <div
                      className="next-year-button d-flex align-items-center justify-content-center"
                      onClick={nextYear}
                    >
                      
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </div>
                </div>
                <div className="calendar-wrap">
                  <table id="calendar">
                    <thead>
                      <tr>
                        <th>Sun</th>
                        <th>Mon</th>
                        <th>Tue</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array(6)
                        .fill(null)
                        .map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            {drawDays()
                              .slice(rowIndex * 7, rowIndex * 7 + 7)
                              .map((dayObj, dayIndex) => (
                                <td
                                  key={dayIndex}
                                  className={
                                    dayObj.isDisabled
                                      ? "disabled"
                                      : selectedDay &&
                                        selectedDay.getDate() ===
                                          parseInt(dayObj.day)
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() =>
                                    !dayObj.isDisabled && clickDay(dayObj.day)
                                  }
                                >
                                  {dayObj.day}
                                </td>
                              ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='btn-holder m-3'>
          <button className='btn btn-weight' onClick={()=> { nextScreen('#sec-result')}}>
            Next
          </button>
        </div> */}
        </div>
      </section>
    </article>
  );
}
