import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { CustomEase } from "gsap/CustomEase";
import { RoughEase } from "gsap/EasePack";

import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";
// import logo from './logo.svg';
import './App.scss';
import 'animate.css';

import Intro from './sections/Intro';
import Gender from './sections/Gender';
import Weight from './sections/Weight';
import Height from './sections/Height';
import { useDispatch } from 'react-redux';
import { updatePath } from './features/config/configSlice';
import { useAppSelector } from './app/hooks';
import BioInputs from './sections/BioInputs';
import { motion, useTransform, useScroll } from "framer-motion";
import { Console } from 'console';
import BioResults from './sections/BioResults';
function App() {

  const [loading, setLoading] = useState<boolean>(true);
  const [scrollPos, setScrollPos] = useState(0);
  var tmrScroll = null;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const dispatch = useDispatch();
  const path = useAppSelector((state) => state.config.path);
  const [firstScroll, setFirstScroll] = useState<number>(0);
  const [scrType , setScrType] = useState<string>("");

  const containerRef = useRef<HTMLDivElement>(null);

  //  gsap.registerPlugin(useGSAP,Flip,ScrollTrigger,Observer,ScrollToPlugin,Draggable,MotionPathPlugin,EaselPlugin,PixiPlugin,TextPlugin,RoughEase,CustomEase);
  const sections = gsap.utils.toArray(".panel"); // ["#sec-height", "#sec-weight", "sec-gender"];
var tween:any = null;

  return (<>
    {/* { loading?<>
<div className='center-wrapper'>
  
<Lottie
className='center'
      loop
      animationData={loadIcon}
      play
      style={{ width: 150, height: 150 }}
    />

</div>

</>: <></>} */}
    <div >
      <div className='body'>
        &nbsp;
      </div>
      <Intro className='' />
      <BioInputs />
      {/* <BioInputs containerRef={containerRef} scrX={firstScroll} /> */}
   <BioResults />
      {/* <Gender />
  <Weight />
  <Height /> */}
    </div>
  </>
  );
}

export default App;
