import React, { Component } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Gender from './Gender';
import Weight from './Weight';
import Height from './Height';
import Age from './Age';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

gsap.registerPlugin(ScrollTrigger);

type MyProps = {};

type MyStates = {
  containerRef: React.RefObject<HTMLDivElement>;
  tween: any;
};

export default class BioInputs extends Component<MyProps, MyStates> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      containerRef: React.createRef<HTMLDivElement>(),
      tween: null
    };

this.nextScreen = this.nextScreen.bind(this);
  }

  // nextScreen(screenName: string) {
  //   const targetElem = document.querySelector<HTMLElement>(screenName);
  //   const { tween } = this.state;

  //   if (screenName === '#sec-result') {
  //     gsap.to(window, {
  //       scrollTo: { y: window.innerHeight * 4, autoKill: false },
  //       duration: 1,
  //     });
  //     return;
  //   }

  //   const sections = gsap.utils.toArray<HTMLElement>('.panel');

  //   if (targetElem && tween) {
  //     const index = sections.indexOf(targetElem);
  //     if (index !== -1) {
  //       gsap.to(window, {
  //         scrollTo: {
  //           y: index * window.innerHeight,
  //           autoKill: false,
  //         },
  //         duration: 1,
  //       });
  //     }
  //   }
  // }

  nextScreen(screenName: string) {
    const targetElem = document.querySelector<HTMLElement>(screenName);
    var sections = null;
    if(screenName == "#sec-result"){
      // sections = gsap.utils.toArray('section');

      gsap.to(window, {
        scrollTo: {
            y: (window.innerHeight * 4),
            autoKill: false
        },
        duration: 1
    });

    return;
    }
    
     sections = gsap.utils.toArray('.panel');

    const { tween } = this.state; // Access the tween from the state

    if (targetElem && tween) {
        let totalScroll = tween.scrollTrigger.end - tween.scrollTrigger.start;
        let totalMovement = (sections.length - 1) * targetElem.offsetWidth;

        // Calculate the exact y scroll position for the target element
        let y = Math.round(tween.scrollTrigger.start + (targetElem.offsetLeft / totalMovement) * totalScroll);

        gsap.to(window, {
            scrollTo: {
                y: y-100,
                autoKill: false
            },
            duration: 1
        });
    }
}

  componentDidMount(): void {
    // Fetch panels inside the container
    const sections = gsap.utils.toArray('.panel');

    // Set up GSAP horizontal scroll with ScrollTrigger

    this.setState({tween:     gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: this.state.containerRef.current,
        pin: true, // Pin the container
        scrub: 1,  // Smooth transition
         snap: 1 / (sections.length - 1), // Snap to each section
        end: () => `+=${this.state.containerRef.current?.offsetWidth}`, // End after scrolling through all panels
      },
    })});


    gsap.to(".scroll-ind", {
      duration: 1,
      repeat: -1,
      y: 10
    });

  }

  componentWillUnmount(): void {
    // Cleanup to avoid multiple ScrollTriggers
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  }

  render() {
    return (
      <section id="sec-bio" className="sec-bio"  ref={this.state.containerRef}>
        <div id="panels-container" className="panels-container" ref={this.state.containerRef}>
            <Gender nextScreen={this.nextScreen} />
            <Weight nextScreen={this.nextScreen}/>
            <Height nextScreen={this.nextScreen} /> 
            <Age  nextScreen={this.nextScreen}/>
        </div>
        <div className='scroll-message'>
          <FontAwesomeIcon className='scroll-ind' icon={faAngleDoubleDown} />
        </div>
      </section>
    );
  }
}
