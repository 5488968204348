import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';


export interface ConfigState {
    title: string;
    path: string;
    notification:string;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: ConfigState = {
    title: "HOME",
    path: "intro",
    notification: "",
    status: 'idle'
}


export const configSlice = createSlice({
    name: "config",
    initialState,
    reducers:{
        updatePath: (state,  action: PayloadAction<string>) => {
            state.path = action.payload
            localStorage.setItem('path', action.payload);
        },
        updateTitle: (state,  action: PayloadAction<string>) => {
            state.title = action.payload
            localStorage.setItem('title', action.payload);
        },
       
    },
    
});


export const {updatePath, updateTitle} = configSlice.actions;


export const selectTitle = (state: RootState) => state.config.title;
export const selectPath = (state: RootState) => state.config.path;

export default configSlice.reducer;