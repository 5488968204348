import React, { useEffect } from 'react';

export default function BioResults() {
  useEffect(() => {}, []);

  return (
    <section id="sec-result" className="sec-result">
      <h1 className="mt-3">Results</h1>
      <div className="container mt-3">
        <div className="row">
          <div className="col-6">
            <div className="holder align-content-center">
              <div className="row">
                <div className="col-6">
                  <label className="sub-title">Gender</label>
                </div>
                <div className="col-6">
                  <label>: Male</label>
                </div>
                <div className="col-6">
                  <label className="sub-title">Age</label>
                </div>
                <div className="col-6">
                  <label>: 30</label>
                </div>
                <div className="col-6">
                  <label className="sub-title">Weight</label>
                </div>
                <div className="col-6">
                  <label>: 100 KG</label>
                </div>
                <div className="col-6">
                  <label className="sub-title">Height</label>
                </div>
                <div className="col-6">
                  <label>: 220 CM</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="holder bmi-holder">
              <label className="sub-title">BMI</label>
              <label>: 220 CM</label>
            </div>
          </div>

          <div className="col-6 mt-3">
            <div className="holder">
              <label className="sub-title">Calculation</label>
              <label>
                BMI = mass (kg) / height² (m) = 72.57 / 1.778² = 23.0
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
