import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import maleJson from '../assets/jsons/maleWalk.json';
import femaleJson from '../assets/jsons/femaleWalk.json';
import unkJson from '../assets/jsons/unknownWalk.json';
import { useAppSelector } from '../app/hooks';
import { Dropdown } from 'react-bootstrap';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { CustomEase } from "gsap/CustomEase";
import { RoughEase } from "gsap/EasePack";

import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
gsap.registerPlugin(useGSAP, Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, EaselPlugin, PixiPlugin, TextPlugin, RoughEase, CustomEase);
export default function Gender({ nextScreen }: { nextScreen: any }) {
    const path = useAppSelector((state) => state.config.path);
    const [gender, setGender] = useState<string>("Select a Gender");


    function animateWalk() {
        gsap.fromTo(".gender", { x: 300 }, { x: 0 });
    }

    return (
        <article id="sec-gender" className="sec-gender panel full-screen">
            
            <div className="container-fluid d-flex justify-content-center align-items-center">
                <div className="row w-100">
                    <div className="col-12 text-center">
                        <h1>Select a Gender</h1>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {gender}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { animateWalk(); setGender("Male"); }}>
                                    Male
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { animateWalk(); setGender("Female"); }}>
                                    Female
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { animateWalk(); setGender("Secret"); }}>
                                    Secret
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className="col-12 text-center mt-4">
                        {gender === "Male" ? (
                            <Lottie className="img-fluid gender" loop animationData={maleJson} play />
                        ) : gender === "Female" ? (
                            <Lottie className="img-fluid gender" loop animationData={femaleJson} play />
                        ) : (
                            <Lottie className="img-fluid gender" loop animationData={unkJson} play />
                        )}
                    </div>
                  

                </div>
               
            </div>
            {/* <div className='btn-holder'>
                        <button className='btn btn-weight' onClick={() => {nextScreen("#sec-weight"); }}>
                            Next
                        </button>
                    </div> */}
<div className='right-holder'>
<button className='btn btn-next' onClick={() => {nextScreen("#sec-weight"); }}>
                             <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                        </div>
        </article>
    )
}
