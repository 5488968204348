import React, { useEffect, useState } from 'react';
import imgPerson from "../assets/images/noun-person.png";
export default function HeightRuler() {

    const [calcs, setCalcs] = useState<Number[]>([]);
    const [height, setHeight] = useState<string>("0");

    useEffect(() => {

        var nums: Number[] = [];
        for (let index = 60; index <= 300; index = index + 10) {
            nums.push(index);
        }

        setCalcs(nums.reverse());

    }, []);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-4'>

                    <div className='slidecontainer'>

                        <ul className='ulRuler'>
                            {calcs.map((n, i) => {
                                return <li>{n.toString()}</li>;
                            })}
                        </ul>
                        <input min={60} max={300} onChange={(e) => { setHeight(e.target.value) }} className='slider' type='range' />
                    </div>
                </div>
                <div className='col-8 '>
                    <div className='row'>
                    <div className='col-12  col-md-6 col-sm-12 col-xs-12'>
                    <div className='holder'>
                        <input type='number' min={60} max={300} value={height} onChange={(e) => {
                            setHeight(e.target.value);
                        }} />
                        <span>CM</span>
                    </div>
                    </div>
                    <div className='col-12 col-md-6 col-sm-12 col-xs-12'>
                    <img className='img-fluid' src={imgPerson} />
                    </div>
                    </div>
                    
                </div>
           
            </div>

        </div>
    )
}
