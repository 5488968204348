import React, {useState} from 'react'
import img from "../assets/images/Logo.png";
import sideImg from "../assets/images/Other images/Other images/vecteezy_balanced-plate-with-grilled-chicken-and-vegetables_47599299.png";
import { toSection } from '../helpers/helper';
import { useAppSelector } from '../app/hooks';
import { useDispatch } from 'react-redux';
export default function Intro({className}: {className:string}) {

const path = useAppSelector((state) => state.config.path);
const dispatch = useDispatch();

function nextStep(){
   // toSection("#sec-gender");
   toSection("#sec-bio");
}

    return (
        <section id='sec-init' className={'sec-init ' + className + (path == "intro"? ' animate__animated animate__backInDown animate__delay-1s': ' animate__animated animate__backOutUp animate__delay-1s hidden-section')}>
          
     

            <div className='row'>
                <div className='col-sm-12 col-md-6'>
                    <img className='logo tanimate__animated  tanimate__backInRight tanimate__delay-2s' src={img} alt="logo" />
                    <div className='ttypewriter'> <p>A journey of a thousand miles starts with your first step. Take your first step into your nutrition and understand your body better.</p></div>
                    {/* <a href='#sec-gender' className='mt-3 btn btn-orange animate__animated  animate__fadeInLeft animate__delay-2s'>Let's take a first step</a> */}
                    <button onClick={nextStep} className='mt-3 btn btn-orange tanimate__animated  tanimate__fadeInLeft tanimate__delay-2s'>Let's take a first step</button>
                </div>
                <div className='col-sm-12 col-md-6 '>
                    <img className='side-img' src={sideImg} />
                </div>
            </div>
        </section>
    )
}
